import React, { useEffect, useState } from "react";
import Url from "../../global";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormControl } from "react-bootstrap";
import axios from "axios";
// import { useForm } from "react-hook-form";
import "../../GlobalvalidasiErorr.css";
import { useSelector } from "react-redux";

const EditSuratKeluar = () => {
  const [judulSurat, setJudulSurat] = useState("");
  const [noSurat, setNoSurat] = useState("");
  const [tujuan, setTujuan] = useState([]);
  const [tahunAjaran, setTahunAjaran] = useState("");
  const [tglSurat, setTglSurat] = useState("");
  const [tglSuratFix, setTglSuratFix] = useState("");
  const [semester, setSemester] = useState("");
  const [perihal, setPerihal] = useState("");
  const [lampiran, setLampiran] = useState("");
  const [status, setStatus] = useState("");
  const [msg, setMsg] = useState("");
  const [komentar, setKomentar] = useState("");
  const [tembusan, setTembusan] = useState("");
  const [komentarKetua, setKomentarKetua] = useState("");
  const [komentarSE, setKomentarSE] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);

  const [file, setFile] = useState("");
  const [setError] = useState(null);
  const [setFileUrl] = useState("");
  const allOptions = [
    "Kabid Pendidikan dan Penjamin Mutu",
    "Kabid Sarpras, IT dan Humas",
    "Manajer Bisnis",
    "PAUD IT Al Hasanah 1",
    "PAUD IT Al Hasanah 2",
    "SDIT Al Hasanah 1",
    "SDIT Al Hasanah 2",
    "SMPI Al Hasanah",
    "SMAIT Al Hasanah",
    "Pondok Pesantren",
    "TPQ SDIT Al Hasanah 1",
    "TPQ SMPI Al Hasanah",
    "TPQ PAUDIT Al Hasanah 1",
    "TPQ SIT Al Hasanah 2",
    "Supervisor Qiraati",
  ]; // Isi dengan opsi-opsi yang ada

  const PdfViewer = ({ file }) => {
    return (
      <div>
        {file ? (
          <embed src={file} type="application/pdf" width="80%" height="600px" />
        ) : (
          <p>No PDF file uploaded</p>
        )}
      </div>
    );
  };

  const handleCheckboxChange = (value) => {
    if (tujuan.includes(value)) {
      // Jika nilai sudah ada dalam array, maka hilangkan dari array
      setTujuan(tujuan.filter((item) => item !== value));
    } else {
      // Jika nilai belum ada dalam array, tambahkan ke array
      setTujuan([...tujuan, value]);
    }
  };

  const handleChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setFile(selectedFile);
      setFileUrl(URL.createObjectURL(selectedFile));
    } else {
      setFile(null);
      setError("File harus berupa PDF");
    }
  };

  const handleSelectAll = () => {
    if (tujuan.length === allOptions.length) {
      setTujuan([]);
    } else {
      setTujuan(allOptions);
    }
  };

  useEffect(() => {
    const getSuratKeluarById = async () => {
      try {
        const response = await axios.get(`${Url}/suratKeluar/${id}`);
        setJudulSurat(response.data.strJudulSurat);
        setTglSurat(response.data.dateTglSurat);
        setTglSuratFix(response.data.dateTglSuratFix);
        setSemester(response.data.strSemester);
        setPerihal(response.data.strPerihal);
        setLampiran(response.data.strLampiran);
        setStatus(response.data.strStatus);
        setNoSurat(response.data.strNoSurat);
        // Set data tujuan surat yang ada pada surat yang akan diedit
        setTujuan(JSON.parse(response.data.strTujuan));
        setTahunAjaran(response.data.strTahunAjaran);
        setKomentar(response.data.strKomentar);
        setTembusan(response.data.strTembusan);
        setKomentarKetua(response.data.strKomentarKetua);
        setKomentarSE(response.data.strKomentarSE);
        setFile(response.data.url);
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
        }
      }
    };
    getSuratKeluarById();
  }, [id]);

  const updateSuratKeluar = async (e) => {
    e.preventDefault();
    try {
      await axios.patch(
        `${Url}/suratKeluar/${id}`,
        {
          strJudulSurat: judulSurat,
          strNoSurat: noSurat,
          strTahunAjaran: tahunAjaran,
          strTujuan: JSON.stringify(tujuan),
          dateTglSurat: tglSurat,
          dateTglSuratFix: tglSuratFix,
          strSemester: semester,
          strPerihal: perihal,
          strLampiran: lampiran,
          strStatus: status,
          strKomentar: komentar,
          strTembusan: tembusan,
          strKomentarKetua: komentarKetua,
          strKomentarSE: komentarSE,
          url: file,
        },
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      );
      navigate("/surat-keluar");
    } catch (error) {
      if (error.response) {
        setMsg(error.response.data.msg);
      }
    }
  };

  return (
    <div className="container ">
      <div className="crud shadow-lg p-3 mb-5 mt-5 bg-bSuratody rounded">
        <div
          className=" offset-sm-5 mt-5 mb-4 text-gred"
          style={{ color: "blue" }}
        >
          <h2>
            <b>Edit Surat Keluar</b>
          </h2>
        </div>
        <div className="card is-shadowless">
          <div className="card-content">
            <div className="content">
              <form className="box" onSubmit={updateSuratKeluar}>
                <p className="has-text-centered">{msg}</p>

                <div className="field">
                  <label className="label">Judul Surat</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={judulSurat}
                        onChange={(e) => setJudulSurat(e.target.value)}
                        required
                      >
                        <option>------Pilih------</option>
                        <option>Surat Pernyataan</option>
                        <option>Surat Pemohonan</option>
                        <option>Surat Undangan</option>
                        <option>Surat Kuasa</option>
                        <option>Surat Pengalaman Kerja</option>
                        <option>Surat Peringantan</option>
                        <option>Surat Agenda Sekolah</option>
                        <option>Surat Perpanjangan KTA</option>
                        <option>Surat Piutang</option>
                        <option>Surat Tugas</option>
                        <option>Surat Pemberitahuan</option>
                        <option>Surat Kode Etik</option>
                        <option>Surat SOP</option>
                        <option>Surat Keterangan</option>
                        <option>
                          Surat Wawancara PPDB, lembar komitmen dan lain - lain.
                        </option>
                        <option>Surat Pinjaman</option>
                        <option>Surat Sertifikat Pelatihan</option>
                        <option>Surat Keputusan</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Nomor Surat</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      placeholder="Nomor Surat"
                      value={noSurat}
                      onChange={(e) => setNoSurat(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tanggal Kirim Surat</label>
                  <div className="control">
                    <Form.Group controlId="duedatetime" className="dtp">
                      <Form.Control
                        type="datetime-local"
                        name="duedatetime"
                        placeholder="Tanggal Kirim Surat"
                        value={tglSurat}
                        onChange={(e) => setTglSurat(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tanggal Surat</label>
                  <div className="control">
                    <Form.Group controlId="duedate" className="dtp">
                      <Form.Control
                        type="date"
                        name="duedate"
                        placeholder="Tanggal Surat"
                        value={tglSuratFix}
                        onChange={(e) => setTglSuratFix(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Semester</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={semester}
                        onChange={(e) => setSemester(e.target.value)}
                        required
                        // disabled="true"
                      >
                        <option>--- Pilih ---</option>
                        <option>Semester 1</option>
                        <option>Semester 2</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tahun Ajaran</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={tahunAjaran}
                        onChange={(e) => setTahunAjaran(e.target.value)}
                        required
                        // disabled="true"
                      >
                        <option>--- Pilih ---</option>
                        <option>2023</option>
                        <option>2024</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Perihal</label>
                  <div className="control">
                    <input
                      type="text"
                      className="input"
                      placeholder="Perihal"
                      value={perihal}
                      onChange={(e) => setPerihal(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Lampiran</label>
                  <div className="control">
                    <input
                      type="number"
                      className="input"
                      placeholder="Lampiran Wajib di Isi"
                      value={lampiran}
                      onChange={(e) => setLampiran(e.target.value)}
                    />
                  </div>
                </div>

                <div className="field">
                  <label className="label">Status Surat Keluar</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      {user &&
                        (user.strRole === "Staff MNJ" ||
                          user.strRole === "Admin") && (
                          <select
                            className="dropdown"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option>Pengajuan Surat Staff MNJ</option>
                            <option>Pengajuan Kabid ke Ketua Yayasan</option>
                            <option>
                              Pengajuan Kabid ke Sekretaris Eksekutif
                            </option>
                            <option>Revisi Kabid Kepegawaian</option>
                            <option>ACC Ketua Yayasan</option>
                            <option>Revisi Ketua Yayasan</option>
                            <option>Ditolak Ketua Yayasan</option>
                            <option>ACC Sekretaris Eksekutif</option>
                            <option>Revisi Sekretaris Eksekutif</option>
                            <option>Ditolak Sekretaris Eksekutif</option>
                          </select>
                        )}

                      {user && user.strRole === "Kabid Kepegawaian" && (
                        <select
                          className="dropdown"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>Pengajuan Surat Staff MNJ</option>
                          <option>Pengajuan Kabid ke Ketua Yayasan</option>
                          <option>
                            Pengajuan Kabid ke Sekretaris Eksekutif
                          </option>
                          <option>Revisi Kabid Kepegawaian</option>
                          <option>ACC Ketua Yayasan</option>
                          <option>Revisi Sekretaris Eksekutif</option>
                          <option>Ditolak Sekretaris Eksekutif</option>
                        </select>
                      )}

                      {user && user.strRole === "Ketua Yayasan" && (
                        <select
                          className="dropdown"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>Pengajuan Kabid ke Ketua Yayasan</option>
                          <option>ACC Ketua Yayasan</option>
                          <option>Revisi Ketua Yayasan</option>
                          <option>Ditolak Ketua Yayasan</option>
                        </select>
                      )}

                      {user && user.strRole === "Sekretaris Eksekutif" && (
                        <select
                          className="dropdown"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option>
                            Pengajuan Kabid ke Sekretaris Eksekutif
                          </option>
                          <option>ACC Sekretaris Eksekutif</option>
                          <option>Revisi Sekretaris Eksekutif</option>
                          <option>Ditolak Sekretaris Eksekutif</option>
                        </select>
                      )}
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">Tujuan</label>

                  <div className="control">
                    <div className="checkbox-list">
                      <label>
                        <input
                          type="checkbox"
                          checked={tujuan.length === allOptions.length}
                          onChange={handleSelectAll}
                        />
                        Select All
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="Kabid Pendidikan dan Penjamin Mutu"
                          checked={tujuan.includes(
                            "Kabid Pendidikan dan Penjamin Mutu"
                          )}
                          onChange={() =>
                            handleCheckboxChange(
                              "Kabid Pendidikan dan Penjamin Mutu"
                            )
                          }
                        />
                        Kabid Pendidikan dan Penjamin Mutu
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          value="Kabid Sarpras, IT dan Humas"
                          checked={tujuan.includes(
                            "Kabid Sarpras, IT dan Humas"
                          )}
                          onChange={() =>
                            handleCheckboxChange("Kabid Sarpras, IT dan Humas")
                          }
                        />
                        Kabid Sarpras, IT dan Humas
                      </label>
                      {/* //ok */}
                      <label>
                        <input
                          type="checkbox"
                          value="Manajer Bisnis"
                          checked={tujuan.includes("Manajer Bisnis")}
                          onChange={() =>
                            handleCheckboxChange("Manajer Bisnis")
                          }
                        />
                        Manajer Bisnis
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="PAUD IT Al Hasanah 1"
                          checked={tujuan.includes("PAUD IT Al Hasanah 1")}
                          onChange={() =>
                            handleCheckboxChange("PAUD IT Al Hasanah 1")
                          }
                        />
                        PAUD IT Al Hasanah 1
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="PAUD IT Al Hasanah 2"
                          checked={tujuan.includes("PAUD IT Al Hasanah 2")}
                          onChange={() =>
                            handleCheckboxChange("PAUD IT Al Hasanah 2")
                          }
                        />
                        PAUD IT Al Hasanah 2
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="SDIT Al Hasanah 1"
                          checked={tujuan.includes("SDIT Al Hasanah 1")}
                          onChange={() =>
                            handleCheckboxChange("SDIT Al Hasanah 1")
                          }
                        />
                        SDIT Al Hasanah 1
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="SDIT Al Hasanah 2"
                          checked={tujuan.includes("SDIT Al Hasanah 2")}
                          onChange={() =>
                            handleCheckboxChange("SDIT Al Hasanah 2")
                          }
                        />
                        SDIT Al Hasanah 2
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="SMPI Al Hasanah"
                          checked={tujuan.includes("SMPI Al Hasanah")}
                          onChange={() =>
                            handleCheckboxChange("SMPI Al Hasanah")
                          }
                        />
                        SMPI Al Hasanah
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="Pondok Pesantren"
                          checked={tujuan.includes("Pondok Pesantren")}
                          onChange={() =>
                            handleCheckboxChange("Pondok Pesantren")
                          }
                        />
                        Pondok Pesantren
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="TPQ SDIT Al Hasanah 1"
                          checked={tujuan.includes("TPQ SDIT Al Hasanah 1")}
                          onChange={() =>
                            handleCheckboxChange("TPQ SDIT Al Hasanah 1")
                          }
                        />
                        TPQ SDIT Al Hasanah 1
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="TPQ SMPI Al Hasanah"
                          checked={tujuan.includes("TPQ SMPI Al Hasanah")}
                          onChange={() =>
                            handleCheckboxChange("TPQ SMPI Al Hasanah")
                          }
                        />
                        TPQ SMPI Al Hasanah
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="SMAIT Al Hasanah"
                          checked={tujuan.includes("SMAIT Al Hasanah")}
                          onChange={() =>
                            handleCheckboxChange("SMAIT Al Hasanah")
                          }
                        />
                        SMAIT Al Hasanah
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="TPQ PAUDIT Al Hasanah 1"
                          checked={tujuan.includes("TPQ PAUDIT Al Hasanah 1")}
                          onChange={() =>
                            handleCheckboxChange("TPQ PAUDIT Al Hasanah 1")
                          }
                        />
                        TPQ PAUDIT Al Hasanah 1
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="TPQ SIT Al Hasanah 2"
                          checked={tujuan.includes("TPQ SIT Al Hasanah 2")}
                          onChange={() =>
                            handleCheckboxChange("TPQ SIT Al Hasanah 2")
                          }
                        />
                        TPQ SIT Al Hasanah 2
                      </label>

                      <label>
                        <input
                          type="checkbox"
                          value="Supervisor Qiraati"
                          checked={tujuan.includes("Supervisor Qiraati")}
                          onChange={() =>
                            handleCheckboxChange("Supervisor Qiraati")
                          }
                        />
                        Supervisor Qiraati
                      </label>
                      {/* ...Tambahkan opsi checkbox lainnya di sini */}
                    </div>
                    <p>Tujuan yang dipilih: {tujuan.join(", ")}</p>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Komentar Kabid</label>
                  <div className="control">
                    {user && user.strRole === "Kabid Kepegawaian" && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar"
                        value={komentar}
                        onChange={(e) => setKomentar(e.target.value)}
                      />
                    )}
                    {user &&
                      (user.strRole === "Ketua Yayasan" ||
                        user.strRole === "Sekretaris Eksekutif") && (
                        <FormControl
                          as="textarea"
                          rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                          placeholder="Komentar"
                          value={komentar}
                          onChange={(e) => setKomentar(e.target.value)}
                          disabled={true}
                        />
                      )}

                    {user && user.strRole === "Admin" && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar"
                        value={komentar}
                        onChange={(e) => setKomentar(e.target.value)}
                      />
                    )}

                    {user && user.strRole === "Staff MNJ" && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar"
                        value={komentar}
                        onChange={(e) => setKomentar(e.target.value)}
                        disabled={true}
                      />
                    )}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Komentar Ketua Yayasan</label>
                  <div className="control">
                    {user && 
                      (user.strRole === "Kabid Kepegawaian" ||
                        user.strRole === "Ketua Yayasan" ||
                        user.strRole === "Admin") && (
                        <FormControl
                          as="textarea"
                          rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                          placeholder="Komentar"
                          value={komentarKetua}
                          onChange={(e) => setKomentarKetua(e.target.value)}
                  
                        />
                      )}

                    {user &&
                      (user.strRole === "Staff MNJ" ||
                        user.strRole === "Sekretaris Eksekutif") && (
                        <FormControl
                          as="textarea"
                          rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                          placeholder="Komentar"
                          value={komentarKetua}
                          onChange={(e) => setKomentarKetua(e.target.value)}
                          disabled={true}
                        />
                      )}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Komentar Sekretaris Eksekutif</label>
                  <div className="control">
                    {user && (user.strRole === "Kabid Kepegawaian" || user.strRole === "Sekretaris Eksekutif" || user.strRole === "Admin") && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar SE"
                        value={komentarSE}
                        onChange={(e) => setKomentarSE(e.target.value)}
                      
                      />
                    )}

                    {user && (user.strRole === "Staff MNJ" || user.strRole === "Ketua Yayasan") && (
                      <FormControl
                        as="textarea"
                        rows={3} // Jumlah baris yang ingin ditampilkan pada textarea
                        placeholder="Komentar SE"
                        value={komentarSE}
                        onChange={(e) => setKomentarSE(e.target.value)}
                        disabled={true}
                      />
                    )}
                  </div>
                </div>

                <div className="field">
                  <label className="label">Tembusan</label>
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="dropdown"
                        value={tembusan}
                        onChange={(e) => setTembusan(e.target.value)}
                        required
                      >
                        <option>--Pilih--</option>
                        <option>Kabid Kepegawaian</option>
                        <option>Kabid Pendidikan dan Penjamin Mutu</option>
                        <option>Kabid Sarpras, IT dan Humas</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="field">
                  <label className="label">Image</label>
                  <div className="control">
                    <div className="file">
                      <label className="file-label">
                        <input
                          type="file"
                          accept="application/pdf"
                          onChange={handleChange}
                          name="file"
                        />
                        {/* <button type="submit">Upload</button> */}
                      </label>
                      {setError && <p className="error">{setError}</p>}
                    </div>
                    <br />
                    <PdfViewer file={file} />
                  </div>
                </div>

                <br />
                <div className="field">
                  <div className="control">
                    <button type="submit" className="button is-success">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSuratKeluar;
